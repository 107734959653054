<template>
  <div>
    <b-card>
      <filter-swapper :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render 
          :form.sync="formSelect"
          :key="keyFormRender" :fields="fieldsSelect" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
          containerButtonsClass="col-sm-12 col-md-7 col-lg-2 col-xl-2 container-button mt-2"
          class="mb-2">
            <template #buttons>
              <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-if="loading.second && rows.length===0" class="d-flex flex-column justify-content-center align-items-center" style="height: 470px">
        <div>
          <feather-icon icon="FileTextIcon" size="52" style="color:#B9B9C7"/>
        </div>
        <div class="text-wrap text-center my-1" style="width:36rem;font-size:14px;color:#B9B9C7">
          Utiliza los selectores disponibles en la parte superior
          para realizar una búsqueda eficiente y acceder a la información deseada.
        </div>
      </div>
      <div v-show="!loading.total" class="table-listfailed">
        <table-render
          :schema.sync="schema"
          :rows="rows"
          :actions="actions"
          :loading="loading.deliveries"
          :stickyHeader="`calc(100vh - 2.5rem)`"
          id="listDeliveriesFailed"
          :tableClass="'with-scroll'"
          :striped="true"
          :showCheckboxes="false"
        >
          <template #marketcenter_code="scope">
            {{getNameMarketcenter(scope.rowdata.marketcenter_code)}}
          </template>
        </table-render>
        <pagination :pagination="pagination" :noDigits="false" :showSize="true"/>
      </div>
      <div class="table-render-skeleton mt-2" v-if="loading.deliveries">
        <b-skeleton-table :rows="7" :columns="4" :table-props="{}" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      fieldsSelect: [],
      formSelect: {},
      shipper_id: null,
      marketcenter_cod: null,
      week: 0,
      schema: [],
      rows: [],
      actions: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 20
      },
      loading: {
        deliveries: false,
        total: true,
        second: true
      },
      integrationsName: Object.freeze({
        ML: 'Mercado Libre',
        FLB: 'Falabella',
        DAF: 'Dafiti',
        PRM: 'Paris Marketplace',
        RPL: 'Ripley',
        HTS: 'Hites',
        KC: 'Kitchen Center',
        WM: 'Walmart'
      }) 
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      marketcenters: 'getMarketcenters',
      deliveries: 'getDeliveriesFailed',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    deliveries() {
      this.rows = this.deliveries?.data?.map((d) => {
        return {
          deliveries: d.body?.deliveries.toString(),
          marketcenter_code: d.body?.marketcenter_code,
          message: d.error?.message,
          created_at: this.$options.filters.moment(d?.creation_date, 'DD-MM-YYYY hh:mm')
        }
      })
      this.loading.second = false
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    shippersByOrganization () {
      const shipperOptions = this.shippersByOrganization.map((shipper) => ({...shipper, name: shipper.name1, text: shipper.name1 }))
      this.setSelectOptions('shipper_id', { options: shipperOptions })
    },
    marketcenters() {
      this.setSelectOptions('marketcenter_code', { options: this.marketcenters?.map((mkc) => ({ id: mkc.code, text: mkc.name })) })
    },
    generalLoading: {
      handler () {
        this.loading.organizations = !!this.generalLoading.getOrganizationsGlobal
        this.loading.marketcenters = !!this.generalLoading.getMarketcenters
        this.loading.deliveries = !!this.generalLoading.getDeliveriesFailed

        this.setSkeletonField('shipper_id', !!this.generalLoading.getShippersByOrganization)
        this.setSkeletonField('marketcenter_code', !!this.loading.marketcenters)
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    'pagination.page' () {
      this.filterList()
    },
    'pagination.limit' () {
      if (this.pagination.page === 1) this.filterList()
    },
    fieldsSelect(fields) {
      const texts = this.$i18nImport(`${this.$session.get('cas_user').country.code.toLowerCase()}.es`)[`${this.$session.get('cas_user').country.code.toLowerCase()}.es`]
      const indexSplice = fields.findIndex(el => el.name === 'shipper_id')
      this.fieldsSelect[indexSplice].label = texts.Shipper
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      const optionsWeek = Object.freeze([{id: 0, text: 'Semana actual'}, {id: 1, text: 'Hace 1 semana'}, {id: 2, text: 'Hace 2 semana'}, {id: 3, text: 'Hace 3 semana'}, {id: 4, text: 'Hace 4 semana'}])
      if (['admin'].includes(this.$session.get('cas_user').role)) {
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'eCommerce/Marketplace', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required', containerClass: 'col-md-3 col-xl-3', persistSearch: true, searchOnType: { fx: e => this.getOrganizationsFetch(e), nChars: 3, debounce: 600 }, options: []},
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Shipper', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, dependency: 'organization_id', change: this.changeShipper, validation: 'required', containerClass: 'col-md-3 col-xl-3' },
          {fieldType: 'FieldSelect', name: 'marketcenter_code', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, dependency: 'organization_id', change: this.changeMarkencenter, validation: 'required', containerClass: 'col-md-3 col-xl-2' },
          {fieldType: 'FieldSelect', name: 'week', label: 'Semana a consultar', useLabel: true, placeholder: 'Semana a consultar', options: optionsWeek, clearable: true, change: this.changeWeek, validation: 'required', containerClass: 'col-md-3 col-xl-2' }
        ]
      } else if (this.$session.get('cas_user').role === 'marketplace') {
        this.organizationId = this.$session.get('cas_user').organization.id
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Shipper', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, change: this.changeShipper, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'marketcenter_code', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, change: this.changeMarkencenter, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'week', label: 'Semana a consultar', useLabel: true, placeholder: 'Semana a consultar', options: optionsWeek, clearable: true, change: this.changeWeek, validation: 'required', containerClass: 'col-md-4 col-xl-3' }
        ]
        this.changeOrganization('Organization', this.$session.get('cas_user').organization)
      } else {
        this.shipper_id = this.$session.get('cas_user')?.shipper?.id
        this.getMarketcenters()
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'marketcenter_code', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, change: this.changeMarkencenter, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'week', label: 'Semana a consultar', useLabel: true, placeholder: 'Semana a consultar', options: optionsWeek, clearable: true, change: this.changeWeek, validation: 'required', containerClass: 'col-md-4 col-xl-3' }
        ]
      }

      this.formSelect.week = {id: 0, text: 'Semana actual'}

      this.schema = [
        {label: 'Envíos', key: 'deliveries'},
        {label: 'Marketcenter', key: 'marketcenter_code', sortable: true, useSlot: true},
        {label: 'Mensaje', key: 'message', sortable: true},
        {label: 'Fecha de creación', key: 'created_at', sortable: true}
      ]
    },
    getOrganizationsFetch(name) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {name, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    filterList(form) {
      this.loading.second = false
      const shipper_id = form?.shipper_id?.id ?? this.shipper_id
      const marketcenter_cod = form?.marketcenter_code.id ?? this.marketcenter_cod
      const data = {
        name: 'getDeliveriesFailed',
        params: {marketcenter_cod, shipper_id},
        queryParams: {limit: this.pagination.limit, week: this.week}
      }
      this.$store.dispatch('fetchService', data)
    },
    changeOrganization (name, value) {
      this.organization_id = value.id
      this.getOrganizationByShipper(value.id)
    },
    getOrganizationByShipper(organization_id) {
      const data = { 
        name: 'getShippersByOrganization',
        queryParams: {page: 1, paginate_by: 99999 },
        params: { organization_id },
        onError: () => {
          this.setSelectOptions('shipper_id', { options: [] })
          this.setSkeletonField('shipper_id', false)
        },
        hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    getMarketcenters() {
      const data = {
        name: 'getMarketcenters',
        params: {shipper_id: this.shipper_id}
      }
      this.$store.dispatch('fetchService', data)
    },
    changeShipper(_, value) {
      this.shipper_id = value.id
      this.formSelect = {
        ...this.formSelect,
        marketcenter_code: null
      }
      this.getMarketcenters()
    },
    changeMarkencenter(_, value) {
      this.marketcenter_cod = value.id
      this.formSelect = {
        ...this.formSelect
      }
    },
    changeWeek(_, value) {
      this.week = value.id
    },
    cleanFilter (e) {
      this.formSelect = {}
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsSelect[index].options = options
        if (!['organization_id'].includes(name)) this.keyFormRender++
      }
    },
    setSkeletonField(name, value) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) this.fieldsSelect[index].useSkeleton = value
    },
    getNameMarketcenter(code) {
      const splitCode = code.split('_')
      if (!this.integrationsName[splitCode[0]]) return code
      return `${this.integrationsName[splitCode[0]]} ${splitCode[1]}`
    }
  }
}
</script>

<style>
.table-listfailed .table-render-skeleton {
  overflow-x: hidden !important;
}
</style>
<template>
  <div style="min-height: 125px">
    <b-card style="min-height: 125px">
      <filter-swapper :buttons="buttons" :controlHeightButtons="controlHeight">
        <template #slot1>
          <form-render :form.sync="formSelect" :key="keyFormRender" :fields="fieldsSelect" @send="filterList" :buttonSend="buttonSend" ref="formFilter"
            containerButtonsClass="col-sm-12 col-md-7 col-lg-4 col-xl-3 container-button mt-2" class="mb-2">
            <template #buttons>
              <b-button variant="outline-light" v-b-tooltip.hover title="Limpiar filtros" class="ml-1" @click="cleanFilter"><feather-icon icon="RefreshCwIcon"/></b-button>
            </template>
          </form-render>
        </template>
      </filter-swapper>
      <div v-if="loading.second && rows.length===0" class="d-flex flex-column justify-content-center align-items-center" style="height: 470px">
        <div>
          <feather-icon icon="FileTextIcon" size="52" style="color:#B9B9C7"/>
        </div>
        <div class="text-wrap text-center my-1" style="width:36rem;font-size:14px;color:#B9B9C7">
          Utiliza los selectores disponibles en la parte superior
          para realizar una búsqueda eficiente y acceder a la información deseada.
        </div>
      </div>
      <div v-show="!loading.total" class="table-listsuccess">
        <table-render
          :schema.sync="schema"
          :rows="rows"
          :loading="loading.deliveries"
          :stickyHeader="`calc(100vh - 2.5rem)`"
          id="listDeliveriesSuccess"
          :tableClass="'with-scroll'"
          :striped="true"
          :showCheckboxes="false"
        >
          <template #status_name="scope">
            <span :class="`table_dot--state ${$options.filters.getStatusClasses()[getStatusDeliveryId(scope.rowdata.status_log)]}`"></span>&nbsp; {{scope.rowdata.status}}
          </template>
        </table-render>
        <pagination :pagination="pagination" :noDigits="pagination.noShowDigits" :showSize="true"/>
      </div>
      <div class="table-render-skeleton mt-2" v-if="loading.deliveries">
        <b-skeleton-table :rows="7" :columns="schema.length || 7" :table-props="{}" />
      </div>
    </b-card>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  data() {
    return {
      controlHeight: { class: 'row mb-2 spacing-label-field'},
      buttonSend: {title: 'Buscar', icon: 'SearchIcon', color: 'warning'},
      buttons: [],
      keyFormRender: 0,
      fieldsSelect: [],
      formSelect: {},
      shipper_id: null,
      marketcenter_code: null,
      schema: [],
      rows: [],
      pagination: {
        page: 1,
        total: 0,
        limit: 20,
        noShowDigits: false
      },
      loading: {
        deliveries: false,
        total: true,
        second: true
      },
      organization_type: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      organizations: 'getOrganizations',
      shippersByOrganization: 'getShippersByOrganization',
      deliveries: 'getDeliveriesSuccess',
      marketcenters: 'getMarketcenterByShipperId',
      generalLoading: 'getLoading'
    })
  },
  watch: {
    deliveries() {
      this.rows = this.deliveries?.deliveries?.map((delivery) => {
        return {
          ...delivery,
          status_name: delivery?.status,
          place: delivery?.place?.level4,
          created_at: this.$options.filters.moment(delivery?.created_at, 'DD-MM-YYYY hh:mm')
        }
      })
      this.loading.second = false
      this.pagination.total = this.deliveries.total
    },
    mySession() {
      if (this.mySession.id) this.setInitialData()
    },
    marketcenters () {
      const options = this.marketcenters?.map(({code, name}) => ({id: code, text: name}))
      this.setSelectOptions('marketcenter_cod', { options })
    },
    organizations () {
      this.setSelectOptions('organization_id', { options: this.organizations.rows })
    },
    shippersByOrganization () {
      const shipperOptions = this.shippersByOrganization.map((shipper) => ({...shipper, name: shipper.name1, text: shipper.name1 }))
      this.setSelectOptions('shipper_id', { options: shipperOptions })
    },
    generalLoading: {
      handler () {
        this.loading.deliveries = !!this.generalLoading.getDeliveriesSuccess

        this.setSkeletonField('shipper_id', !!this.generalLoading.getShippersByOrganization)
        this.setSkeletonField('marketcenter_cod', !!this.generalLoading.getMarketcenterByShipperId)
      },
      deep: true
    },
    loading: {
      handler () {
        const prevTotal = this.loading.total
        this.loading.total = !Object.keys(this.loading).filter(key => key !== 'total').every(key => !this.loading[key])
        if (prevTotal !== this.loading.total) this.keyFormRender++
      },
      deep: true
    },
    'pagination.page'() {
      this.filterList()
    },
    'pagination.limit'() {
      if (this.pagination.page === 1) this.filterList()
    }
  },
  mounted () {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (['admin'].includes(this.$session.get('cas_user').role)) {
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'organization_id', label: 'eCommerce/Marketplace', useLabel: true, clearable: true, change: this.changeOrganization, validation: 'required', persistSearch: true, searchOnType: { fx: e => this.getOrganizationsFetch(e), nChars: 3, debounce: 600 }, options: []},
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, dependency: 'organization_id', change: this.changeShipper, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, dependency: 'shipper_id', change: this.changeMarketcenter, validation: 'required' }
        ]
      } else if (this.$session.get('cas_user').role === 'marketplace') {
        this.organizationId = this.$session.get('cas_user').organization.id
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', clearable: true, change: this.changeShipper, validation: 'required' },
          {fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, dependency: 'shipper_id', change: this.changeMarketcenter, validation: 'required' }
        ]
        this.changeOrganization('organization_id', this.$session.get('cas_user').organization)
      } else {
        this.shipper_id = this.$session.get('cas_user')?.shipper?.id
        this.getMarketcenter(this.shipper_id)
        this.fieldsSelect = [
          {fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, change: this.changeMarketcenter, validation: 'required' }
        ]
      }

      this.schema = [
        {label: 'Nº de referencia', key: 'import_id', sortable: true},
        {label: 'OT', key: 'tracking_number', sortable: true},
        {label: 'Nº de referencia 2', key: 'reference_2', sortable: true},
        {label: 'Estado', key: 'status_name', sortable: true, useSlot: true},
        {label: 'Place_level3', key: 'place', sortable: true},
        {label: 'Fecha de creación', key: 'created_at', sortable: true}
      ]
    },
    getOrganizationsFetch(name) {
      const data = { 
        name: 'getOrganizations',
        queryParams: {name, simplified: true }
      }
      this.$store.dispatch('fetchService', data)
    },
    filterList(form) {
      this.loading.second = false
      const shipper_id = form?.shipper_id?.id ?? this.shipper_id
      const marketcenter_cod = form?.marketcenter_cod?.id ?? this.marketcenter_code
      const data = {
        name: 'getDeliveriesSuccess',
        params: {shipper_id, marketcenter_cod, limit: this.pagination.limit, offset: this.pagination.page}
      }
      this.$store.dispatch('fetchService', data)
    },
    changeOrganization (_, value) {
      const { organization_type } = value
      this.organization_type_id = organization_type.id
      this.organizationId = value.id
      this.fieldsSelect = this.fieldsSelect.filter((field) => !['shipper_id', 'marketcenter_cod'].includes(field.name))
      if (organization_type.id === 1 && ['admin'].includes(this.$session.get('cas_user').role)) {
        this.getOrganizationByShipper(this.organizationId)
        this.cleanFields(['shipper_id'])
        this.fieldsSelect.push(
          {fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', dependency: 'organization_id', change: this.changeShipper, validation: 'required'},
          {fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, dependency: 'shipper_id', change: this.changeMarketcenter, validation: 'required' }
        )
      } else if (organization_type.id === 1 && ['marketplace'].includes(this.$session.get('cas_user').role)) {
        this.getOrganizationByShipper(this.organizationId)
        this.cleanFields(['shipper_id'])
        this.fieldsSelect.push({fieldType: 'FieldSelect', name: 'shipper_id', label: 'Seller', useLabel: true, placeholder: 'Nombre de la empresa', change: this.changeShipper, validation: 'required'})
      } else {
        delete this.formSelect.shipper_id
        this.shipper_id = this.organizationId
        this.getMarketcenter(this.shipper_id)
        this.fieldsSelect.push({fieldType: 'FieldSelect', name: 'marketcenter_cod', label: 'Marketplace', useLabel: true, placeholder: 'Nombre del marketplace', clearable: true, dependency: 'organization_id', change: this.changeMarketcenter, validation: 'required' })
      }
    },
    cleanFilter (e) {
      this.formSelect = {}
    },
    cleanFields(array) {
      array.forEach((el) => this.formSelect[el] = null)
      this.keyFormRender++
    },
    setSelectOptions (name, { options }) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) {
        this.fieldsSelect[index].options = options
        if (!['organization_id'].includes(name)) this.keyFormRender++
      }
    },
    getMarketcenter(shipper_id) {
      const data = {
        name: 'getMarketcenterByShipperId',
        params: {shipper_id}
      }
      this.$store.dispatch('fetchService', data)
    },
    getOrganizationByShipper(organization_id) {
      const data = { 
        name: 'getShippersByOrganization',
        queryParams: {page: 1, paginate_by: 99999 },
        params: { organization_id },
        onError: () => {
          this.setSelectOptions('shipper_id', { options: [] })
          this.setSkeletonField('shipper_id', false)
        },
        hideAlert: true
      }
      this.$store.dispatch('fetchService', data)
    },
    changeShipper(_, value) {
      this.shipper_id = value.id
      this.formSelect = {
        ...this.formSelect,
        marketcenter_cod: null
      }
      this.getMarketcenter(value.id)
    },
    changeMarketcenter(_, value) {
      this.marketcenter_code = value.id
    },
    setSkeletonField(name, value) {
      const index = this.fieldsSelect.findIndex(el => el.name === name)
      if (index !== -1) this.fieldsSelect[index].useSkeleton = value
    },
    getStatusDeliveryId(logs) {
      return logs?.at(-1)?.id
    }
  }
}
</script>

<style>
.table-listsuccess .table-render-skeleton {
  overflow-x: hidden !important;
}
</style>